import css from "./style.module.scss";

export const CollectionCard = ({
  title,
  url,
  image,
}: {
  title: string;
  url: string;
  image: string;
}) => {
  return (
    <div className={css["card"]}>
      <a href={url} rel="noopener noreferrer" target="_blank">
        <img src={image} alt="Teaser" className={css["image"]} loading="lazy" />
        <div>
          <h4 className={css["title"]}>{title}</h4>
        </div>
      </a>
    </div>
  );
};
