import css from "./style.module.scss";
import { Collection } from "../../../pages/api/edu-sharing/collections";
import { CollectionCard } from "../CollectionCard/CollectionCard";
import { DefaultSlider } from "../../common/Slider/Slider";

const CollectionCarousel = ({ collections }: { collections: Collection[] }) => {
  const ArrowNext = (props: any) => (
    <div className={css["carousel-next-button"]} onClick={props.onClick}></div>
  );

  const ArrowPrev = (props: any) => (
    <div className={css["carousel-prev-button"]} onClick={props.onClick}></div>
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1265,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1660,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 2055,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 2450,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };

  return (
    <div className={css["carousel-main-layout"]}>
      <DefaultSlider {...settings}>
        {collections.map((col) => (
          <div className={css["slide-wrapper"]} key={col.url}>
            <CollectionCard title={col.title} url={col.url} image={col.image} />
          </div>
        ))}
      </DefaultSlider>
    </div>
  );
};

// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
// import "swiper/css";
// import { useRef } from "react";
// const CollectionCarousel = ({ collections }: { collections: Collection[] }) => {
//   const swiperRef = useRef<SwiperClass>();

//   return (
//     <div className={css["carousel-main-layout"]}>
//       <div
//         className={css["carousel-prev-button"]}
//         onClick={() => {
//           if (swiperRef.current) swiperRef.current.slidePrev();
//         }}
//       ></div>
//       <div
//         className={css["carousel-next-button"]}
//         onClick={() => {
//           if (swiperRef.current) swiperRef.current.slideNext();
//         }}
//       ></div>
//       <Swiper
//         spaceBetween={24}
//         slidesPerView={6}
//         breakpoints={{
//           0: {
//             slidesPerView: 1,
//           },
//           870: {
//             slidesPerView: 2,
//           },
//           1265: {
//             slidesPerView: 3,
//           },
//           1660: {
//             slidesPerView: 4,
//           },
//           2055: {
//             slidesPerView: 5,
//           },
//           2450: {
//             slidesPerView: 6,
//           },
//         }}
//         allowTouchMove={false}
//         onSwiper={(swiper: any) => {
//           swiperRef.current = swiper;
//         }}
//       >
//         {collections.map((col) => (
//           <SwiperSlide key={col.url}>
//             <CollectionCard title={col.title} url={col.url} image={col.image} />
//           </SwiperSlide>
//         ))}
//       </Swiper>
//     </div>
//   );
// };

export default CollectionCarousel;
